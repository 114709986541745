// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `thead tr {
  background: #ebf2ff;
}
thead tr th {
  padding: 18px 18px 18px 25px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  color: #3053d0;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

tbody td {
  height: 55px;
  padding: 8px 8px 8px 25px;
  justify-content: space-between;
  align-items: center;
  color: #1d1d1d;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}`, "",{"version":3,"sources":["webpack://./src/components/userflowComponents/paymentHistory/payhistory.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AAAE;EAGE,4BAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;EACA,cAAA;EACA,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAAJ;;AAKE;EAGE,YAAA;EACA,yBAAA;EACA,8BAAA;EACA,mBAAA;EACA,cAAA;EACA,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAJJ","sourcesContent":["thead tr {\n  background: #ebf2ff;\n  th {\n    // display: flex;\n    // width: 142px;\n    padding: 18px 18px 18px 25px;\n    justify-content: space-between;\n    align-items: center;\n    align-self: stretch;\n    color: #3053d0;\n    font-family: Poppins;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n  }\n}\n\ntbody {\n  td {\n    // display: flex;\n    // width: 142px;\n    height: 55px;\n    padding: 8px 8px 8px 25px;\n    justify-content: space-between;\n    align-items: center;\n    color: #1d1d1d;\n    font-family: Poppins;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
