// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.support-inputs input {
  width: 100%;
}
.support-inputs textarea {
  width: 100%;
}
.support-inputs select {
  padding-left: 16px;
  padding-top: 9px;
  padding-bottom: 9px;
}

.support-card-1:hover {
  background: var(--white-tint-blue, #f4f7fa);
}

.support-card-1:hover .heading-box {
  color: #1020ae;
  text-decoration: underline;
}

.highlight {
  background: #db0d0d;
}

@media (max-width: 768px) {
  .support-hero {
    padding: 130px 30px;
  }
  .circle-footer-contat {
    display: none;
  }
  .support-card-1 {
    width: 100%;
    padding-right: 0;
  }
  .support-contact {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/subComponents/supportpage/supportHero/supportHero.scss"],"names":[],"mappings":"AACE;EACE,WAAA;AAAJ;AAEE;EACE,WAAA;AAAJ;AAEE;EACE,kBAAA;EACA,gBAAA;EACA,mBAAA;AAAJ;;AAIA;EACE,2CAAA;AADF;;AAGA;EACE,cAAA;EACA,0BAAA;AAAF;;AAGA;EACE,mBAAA;AAAF;;AAGA;EACE;IACE,mBAAA;EAAF;EAEA;IACE,aAAA;EAAF;EAGA;IACE,WAAA;IACA,gBAAA;EADF;EAGA;IACE,WAAA;EADF;AACF","sourcesContent":[".support-inputs {\n  input {\n    width: 100%;\n  }\n  textarea {\n    width: 100%;\n  }\n  select {\n    padding-left: 16px;\n    padding-top: 9px;\n    padding-bottom: 9px;\n  }\n}\n\n.support-card-1:hover {\n  background: var(--white-tint-blue, #f4f7fa);\n}\n.support-card-1:hover .heading-box {\n  color: #1020ae;\n  text-decoration: underline;\n}\n\n.highlight {\n  background: #db0d0d;\n}\n\n@media (max-width: 768px) {\n  .support-hero {\n    padding: 130px 30px;\n  }\n  .circle-footer-contat {\n    display: none;\n  }\n\n  .support-card-1 {\n    width: 100%;\n    padding-right: 0;\n  }\n  .support-contact{\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
