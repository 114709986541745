// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fish-black {
  color: #2c2e32;
  font-variant-numeric: ordinal;
  font-feature-settings: "dlig" on;
  font-family: Poppins;
  font-size: 106.286px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  white-space: nowrap;
}

.fish-white {
  color: #2c2e32;
  font-variant-numeric: ordinal;
  font-feature-settings: "dlig" on;
  font-family: Poppins;
  font-size: 106.286px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  -webkit-text-stroke: 1px black;
  color: white;
  white-space: nowrap;
}

.fish-in-pond {
  overflow: hidden;
}

.fish-svg {
  width: 63px;
  height: 56px;
}
.fish-svg img {
  width: 63px;
  height: 56px;
}

.fishh {
  animation: scroll 10s linear infinite;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-34%);
  }
}
@media (min-width: 800px) and (max-width: 1024px) {
  .fish-in-pond {
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .main-cover {
    flex-wrap: wrap;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/subComponents/freetrails/fishanimate/fish.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,6BAAA;EACA,gCAAA;EACA,oBAAA;EACA,oBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EAGA,mBAAA;AADF;;AAGA;EACE,cAAA;EACA,6BAAA;EACA,gCAAA;EACA,oBAAA;EACA,oBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,8BAAA;EACA,YAAA;EACA,mBAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,WAAA;EACA,YAAA;AAAF;AACE;EACE,WAAA;EACA,YAAA;AACJ;;AAGA;EACE,qCAAA;AAAF;;AAGA;EACE;IACE,wBAAA;EAAF;EAEA;IACE,2BAAA;EAAF;AACF;AAGA;EACE;IACE,WAAA;EADF;AACF;AAIA;EACE;IACE,eAAA;EAFF;AACF","sourcesContent":[".fish-black {\n  color: #2c2e32;\n  font-variant-numeric: ordinal;\n  font-feature-settings: \"dlig\" on;\n  font-family: Poppins;\n  font-size: 106.286px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n  //   -webkit-text-stroke: 1px black;\n  //   color: white;\n  white-space: nowrap;\n}\n.fish-white {\n  color: #2c2e32;\n  font-variant-numeric: ordinal;\n  font-feature-settings: \"dlig\" on;\n  font-family: Poppins;\n  font-size: 106.286px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n  -webkit-text-stroke: 1px black;\n  color: white;\n  white-space: nowrap;\n}\n\n.fish-in-pond {\n  overflow: hidden;\n}\n\n.fish-svg {\n  width: 63px;\n  height: 56px;\n  img {\n    width: 63px;\n    height: 56px;\n  }\n}\n\n.fishh {\n  animation: scroll 10s linear infinite;\n}\n\n@keyframes scroll {\n  from {\n    transform: translateX(0);\n  }\n  to {\n    transform: translateX(calc(-34%));\n  }\n}\n\n@media (min-width: 800px) and (max-width: 1024px) {\n  .fish-in-pond {\n    width: 100%;\n  }\n}\n\n@media (max-width: 1024px) {\n  .main-cover {\n    flex-wrap: wrap;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
