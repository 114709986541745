// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs-container .active {
  color: #3053d0;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 23px;
  background: #eaf7ff;
  opacity: 1 !important;
}

.tabs-container .active .tab {
  opacity: 1;
}

.tabs-container .tab {
  text-align: left;
  width: 63%;
  opacity: 0.5;
}

@media (max-width: 768px) {
  .serp-checker {
    flex-wrap: wrap;
    padding: 70px 20px !important;
  }
  .inputs {
    flex-wrap: wrap;
    gap: 20px;
  }
  .head-serp {
    margin-top: 50px;
  }
  .head-serp p {
    width: 100%;
  }
  .byS {
    flex-wrap: wrap;
    justify-content: center !important;
    gap: 20px;
  }
  .radio-section {
    flex-wrap: wrap;
    padding-top: 60px;
  }
  .main-calculate {
    flex-wrap: wrap;
  }
  .main-roas {
    padding: 20px 20px;
  }
  .generator {
    flex-wrap: wrap;
    padding: 20px;
  }
  .faq-input input {
    width: 100%;
  }
  .number-of-question {
    flex-wrap: wrap;
  }
  .faq-input textarea {
    padding: 0;
  }
  .hero-faq p {
    width: 100%;
  }
  .tabs-container {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/pages/othertools/tools.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EAEA,mBAAA;EACA,mBAAA;EACA,qBAAA;AAAF;;AAEA;EACE,UAAA;AACF;;AACA;EACE,gBAAA;EACA,UAAA;EACA,YAAA;AAEF;;AAAA;EACE;IACE,eAAA;IACA,6BAAA;EAGF;EAAA;IACE,eAAA;IACA,SAAA;EAEF;EACA;IACE,gBAAA;EACF;EAEA;IACE,WAAA;EAAF;EAEA;IACE,eAAA;IACA,kCAAA;IACA,SAAA;EAAF;EAGA;IACE,eAAA;IACA,iBAAA;EADF;EAIA;IACE,eAAA;EAFF;EAKA;IACE,kBAAA;EAHF;EAMA;IACE,eAAA;IACA,aAAA;EAJF;EAOA;IACE,WAAA;EALF;EAQA;IACE,eAAA;EANF;EAQA;IACE,UAAA;EANF;EAQA;IACE,WAAA;EANF;EASA;IACE,WAAA;EAPF;AACF","sourcesContent":[".tabs-container .active {\n  color: #3053d0;\n  font-family: Poppins;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n\n  border-radius: 23px;\n  background: #eaf7ff;\n  opacity: 1 !important;\n}\n.tabs-container .active .tab {\n  opacity: 1;\n}\n.tabs-container .tab {\n  text-align: left;\n  width: 63%;\n  opacity: 0.5;\n}\n@media (max-width: 768px) {\n  .serp-checker {\n    flex-wrap: wrap;\n    padding: 70px 20px !important;\n  }\n\n  .inputs {\n    flex-wrap: wrap;\n    gap: 20px;\n  }\n\n  .head-serp {\n    margin-top: 50px;\n  }\n\n  .head-serp p {\n    width: 100%;\n  }\n  .byS {\n    flex-wrap: wrap;\n    justify-content: center !important;\n    gap: 20px;\n  }\n\n  .radio-section {\n    flex-wrap: wrap;\n    padding-top: 60px;\n  }\n\n  .main-calculate {\n    flex-wrap: wrap;\n  }\n\n  .main-roas {\n    padding: 20px 20px;\n  }\n\n  .generator {\n    flex-wrap: wrap;\n    padding: 20px;\n  }\n\n  .faq-input input {\n    width: 100%;\n  }\n\n  .number-of-question {\n    flex-wrap: wrap;\n  }\n  .faq-input textarea {\n    padding: 0;\n  }\n  .hero-faq p {\n    width: 100%;\n  }\n\n  .tabs-container {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
