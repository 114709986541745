import React from "react";
import Supporthero from "../../subComponents/supportpage/supportHero/Supporthero";
import Cnctsup from "../../subComponents/supportpage/contactSupport/Cnctsup";

const Support = () => {
  return (
    <>
      <Supporthero />
      <Cnctsup />
    </>
  );
};

export default Support;
