// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.head-cover {
  margin-left: 72px;
}
.head-cover h1 {
  color: #2c2e32;
  font-variant-numeric: ordinal;
  font-feature-settings: "dlig" on;
  font-family: Poppins;
  font-size: 31px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 290px;
}

.free-search {
  display: flex;
  padding: 36.437px 71.278px 32px 33.722px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 38.563px;
  border-radius: 21px;
  background: #e9eafb;
  width: 322px;
}

.quality-search h1 {
  color: #3040d0;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 31.2px */
}
.quality-search p {
  color: #3040d0;
  font-size: 16.384px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 21.3px */
}`, "",{"version":3,"sources":["webpack://./src/components/subComponents/freetrails/ftCover/cover.scss"],"names":[],"mappings":"AAAA;EAYE,iBAAA;AAVF;AADE;EACE,cAAA;EACA,6BAAA;EACA,gCAAA;EACA,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,YAAA;AAGJ;;AAEA;EACE,aAAA;EACA,wCAAA;EACA,sBAAA;EACA,yBAAA;EACA,uBAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,YAAA;AACF;;AAGE;EACE,cAAA;EAEA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,WAAA;AADJ;AAGE;EACE,cAAA;EAEA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,WAAA;AAFJ","sourcesContent":[".head-cover {\n  h1 {\n    color: #2c2e32;\n    font-variant-numeric: ordinal;\n    font-feature-settings: \"dlig\" on;\n    font-family: Poppins;\n    font-size: 31px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: normal;\n    width: 290px;\n  }\n  margin-left: 72px;\n}\n\n.free-search {\n  display: flex;\n  padding: 36.437px 71.278px 32px 33.722px;\n  flex-direction: column;\n  justify-content: flex-end;\n  align-items: flex-start;\n  gap: 38.563px;\n  border-radius: 21px;\n  background: #e9eafb;\n  width: 322px;\n}\n\n.quality-search {\n  h1 {\n    color: #3040d0;\n    // font-family: \"Roboto\";\n    font-size: 24px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 130%; /* 31.2px */\n  }\n  p {\n    color: #3040d0;\n    // font-family: \"Roboto\";\n    font-size: 16.384px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 130%; /* 21.3px */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
