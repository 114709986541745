// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion-header-gs {
  display: flex;
  padding: 17px 22px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  width: 100%;
}

.accordion-item-gs {
  border-radius: 8px;
  background: #fff;
  /* nav */
  box-shadow: 0px 4px 24px 1px rgba(0, 0, 0, 0.05);
  width: 664px;
  padding: 10px;
}

.not-asnwer p {
  color: var(--black-80, #2c2e32);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.7;
}
.not-asnwer button {
  border-radius: 4px;
  border: 1px solid var(--color-primary-100, #3040d0);
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--color-primary-100, #3040d0);
  /* Button/Small */
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}`, "",{"version":3,"sources":["webpack://./src/components/subComponents/Qa/gettingStart/gs.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EAEA,uBAAA;EACA,QAAA;EACA,mBAAA;EACA,WAAA;AAAF;;AAGA;EACE,kBAAA;EACA,gBAAA;EAEA,QAAA;EACA,gDAAA;EAEA,YAAA;EACA,aAAA;AAFF;;AAME;EACE,+BAAA;EACA,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,YAAA;AAHJ;AAKE;EACE,kBAAA;EACA,mDAAA;EACA,aAAA;EACA,iBAAA;EACA,uBAAA;EACA,mBAAA;EACA,QAAA;EACA,wCAAA;EAEA,iBAAA;EACA,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAJJ","sourcesContent":[".accordion-header-gs {\n  display: flex;\n  padding: 17px 22px;\n  //   flex-direction: column;\n  align-items: flex-start;\n  gap: 8px;\n  align-self: stretch;\n  width: 100%;\n}\n\n.accordion-item-gs {\n  border-radius: 8px;\n  background: #fff;\n\n  /* nav */\n  box-shadow: 0px 4px 24px 1px rgba(0, 0, 0, 0.05);\n\n  width: 664px;\n  padding: 10px;\n}\n\n.not-asnwer {\n  p {\n    color: var(--black-80, #2c2e32);\n    font-family: Poppins;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n    opacity: 0.7;\n  }\n  button {\n    border-radius: 4px;\n    border: 1px solid var(--color-primary-100, #3040d0);\n    display: flex;\n    padding: 8px 16px;\n    justify-content: center;\n    align-items: center;\n    gap: 8px;\n    color: var(--color-primary-100, #3040d0);\n\n    /* Button/Small */\n    font-family: Poppins;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
